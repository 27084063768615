import React from "react";
import Layout from "../components/Layout";
import Helmet from "react-helmet";


export default function NotFound() {
  return (
    <Layout>
      <Helmet>
        <title>Page Not Found | Globelynx</title>
        <meta name="og:title" content="Globelynx - About" />
        <meta
          name="description"
          content="Globelynx connects more experts to more broadcasters in more countries than anyone else"
        />
      </Helmet>
      <div className="container">
        <div className="row">
          <div className="xs-12 md-6 mx-auto">
            <div id="countUp" style={{ padding: 80 }}>
              <h2 className="custom-heading" style={{ textAlign: "center" }}>
                404
              </h2>{" "}
              <h2 style={{ textAlign: "center" }}>Page not found</h2>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
